import { ColorMode, extendTheme } from '@chakra-ui/react';

import { colors } from './colors';
import { buttonConfig, tooltipConfig } from './config';

const createTheme = (colorMode: ColorMode) =>
  extendTheme({
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    colors: colorMode === 'light' ? colors.light : colors.dark,
    fontSizes: {
      '2xs': '0.625rem', // 10px
      xs: '0.75rem', // 12px
      sm: '0.875rem', // 14px
      md: '1rem', // 16px
      lg: '1.125rem', // 18px
      xl: '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '2rem', // 32px
    },
    styles: {
      global: {
        body: {
          color: 'brand.gray.600',
          background: 'brand.gray.50',
          fontFamily: 'Outfit, sans-serif',
        },
        heading: {
          fontFamily: 'Outfit, sans-serif',
        },
        a: {
          cursor: 'pointer',
        },
      },
    },
    components: {
      Button: buttonConfig,
      Tooltip: tooltipConfig,
    },
  });

export const theme = <const>{
  light: createTheme('light'),
  dark: createTheme('dark'),
};

// export for chakra-cli
export default theme.light;
