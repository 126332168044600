// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const MantleIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="mantle_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 314 311"
    {...props}
  >
    <style>{'.mantle_svg__st0{fill:#fff}'}</style>
    <path d="M0 0h314v311H0z" />
    <path
      d="M155.5 289.1v-45.7c-4.6 0-9.3-.4-13.9-1.1l-7.2 45.1c7 1.2 14.1 1.7 21.1 1.7zm21-1.6c7-1.1 13.9-2.7 20.6-4.9L183 239.1c-4.4 1.4-9 2.5-13.6 3.2l7.1 45.2zm-62.6-4.9 14.1-43.4c-4.4-1.4-8.7-3.2-12.9-5.3l-20.8 40.7c6.3 3.1 12.9 5.8 19.6 8zm102.7-8c6.3-3.2 12.3-6.9 18-11l-27-36.9c-3.7 2.7-7.7 5.2-11.9 7.2l20.9 40.7zM76.3 263.5l27-36.9c-3.7-2.7-7.3-5.7-10.6-9l-32.4 32.3c5 4.9 10.4 9.5 16 13.6zm174.2-13.4.3-.3c5-4.9 9.6-10.3 13.7-15.9l-37-26.9c-2.7 3.7-5.8 7.3-9 10.5l-.5.5 15.6 14.7 16.9 17.4zM46.6 233.8l37-26.9c-2.7-3.7-5.2-7.7-7.3-11.8l-40.8 20.7c3.2 6.3 6.9 12.3 11.1 18zm228.9-17.9c3.2-6.2 5.9-12.8 8.1-19.5l-43.6-14c-1.4 4.4-3.2 8.7-5.3 12.8l40.8 20.7zM27.4 196.4 71 182.3c-1.4-4.4-2.5-8.9-3.2-13.5l-45.3 7.1c1.1 6.9 2.8 13.8 4.9 20.5zM288.5 176c1.1-6.9 1.7-14 1.7-21h-45.8c0 4.6-.4 9.3-1.1 13.9l45.2 7.1zM66.7 154.9c0-4.6.4-9.3 1.1-13.9l-45.3-7.1c-1.1 6.9-1.6 14-1.7 21h45.9zm176.6-13.8 45.3-7.1c-1.1-6.9-2.7-13.8-4.9-20.5l-43.6 14c1.4 4.5 2.5 9 3.2 13.6zM71 127.5c1.4-4.4 3.2-8.7 5.3-12.8L35.5 94c-3.2 6.2-5.9 12.8-8.1 19.5l43.6 14zm163.8-12.7 40.9-20.7c-3.2-6.3-6.9-12.3-11.1-18L227.5 103c2.7 3.7 5.2 7.7 7.3 11.8zM83.6 102.9c2.7-3.7 5.8-7.3 9.1-10.6l.1-.1-32.4-32.3-.1.1c-5 5-9.6 10.3-13.7 16l37 26.9zm134.9-10.4 9.8-9.9 22.5-22.4-.1-.1c-5-4.9-10.3-9.5-15.9-13.6l-27 36.9c3.7 2.7 7.3 5.7 10.5 9l.2.1zm-115.1-9.3c3.7-2.7 7.7-5.1 11.9-7.2L94.5 35.3c-6.3 3.2-12.3 6.9-18 11l26.9 36.9zm92.6-7.1 20.9-40.7c-6.3-3.2-12.8-5.9-19.5-8.1l-14.2 43.4c4.3 1.5 8.6 3.3 12.8 5.4zm-67.8-5.4c4.4-1.4 9-2.5 13.6-3.2l-7.1-45.1c-6.9 1.1-13.9 2.7-20.6 4.9l14.1 43.4zm41.3-3.2 7.2-45.1c-6.9-1.1-14-1.7-21-1.7v45.7c4.6 0 9.3.4 13.8 1.1z"
      className="mantle_svg__st0"
    />
    <path
      d="M155.5 245.5v-45.9c-3.9 0-7.7-.5-11.5-1.5l-12 44.3c7.7 2 15.6 3.1 23.5 3.1zm23.5-3.1c7.7-2 15-5 21.9-9l-23.2-39.7c-3.3 1.9-6.9 3.4-10.6 4.4l11.9 44.3zm-68.8-9 23.2-39.7c-3.3-1.9-6.4-4.3-9.2-7l-.4-.4-14.6 15.3-18 17.3.3.3c5.6 5.5 11.9 10.3 18.7 14.2zm109.4-14.2c5.6-5.6 10.5-11.8 14.4-18.6l-40.1-23c-1.9 3.3-4.3 6.3-7.1 9.1l32.8 32.5zM77 200.5l40.1-22.9c-1.9-3.3-3.4-6.8-4.4-10.5L68 178.9c2 7.5 5.1 14.8 9 21.6zM243.1 179c2-7.6 3.1-15.4 3.1-23.2h-46.3c0 3.8-.5 7.7-1.5 11.4l44.7 11.8zm-131.8-23.3c0-3.8.5-7.7 1.5-11.4L68 132.5c-2 7.6-3.1 15.4-3.1 23.2h46.4zm87-11.3 44.8-11.8c-2-7.6-5.1-14.9-9-21.7L194 133.8c1.8 3.4 3.3 6.9 4.3 10.6zm-81.1-10.6c1.9-3.2 4.2-6.3 7-9l.1-.1-16.6-16.4L91.8 92l-.3.3c-5.6 5.5-10.4 11.8-14.4 18.5l40.1 23zm-9.5-25.5.2.2-.2-.2zm79.1 16.5 16.4-16.3L220 92.7l-.4-.4C214 86.7 207.7 82 200.9 78l-23.2 39.7c3.3 1.9 6.3 4.2 9.1 6.9v.2zm-53.4-7.1c3.3-1.9 6.9-3.4 10.6-4.4L132.2 69c-7.7 2-15 5-21.9 9l23.1 39.7zm33.6-4.3 12-44.3c-7.6-2-15.5-3.1-23.4-3.1v45.9c3.8 0 7.7.5 11.4 1.5z"
      className="mantle_svg__st0"
    />
  </Icon>
);
