import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const tooltipConfig: ComponentSingleStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    borderRadius: 16,
    py: 2,
    px: 4,
  },
};
