export const colors = <const>{
  dark: {
    brand: {
      staticWhite: '#fff',
      primaryBlue: '#29A3CC',
      boxWhite: ' rgba(255, 255, 255, 0.3)',
      offWhite: '#eee7e7',
      lightBrown:'#6a2e44',
      purple: {
        100: '#675FCC',
        500: '#AD13CC',
      },
      pink: '#D97C78',
      gray: {
        0: '#212027', // main section
        50: '#232323',
        100: '#29292C',
        200: '#393844',
        300: '#65646E',
        400: '#77758A',
        500: '#B5B4BC',
        600: '#CECDD5',
        700: '#EFEFEF',
        800: '#FAFAFA',
        900: '#FFF',
      },
      staticGray: {
        100: '#F4F4F6', // lightmode bg
        500: '#29272F', // menu bg
        900: '#0D0D0D', // darkmode bg
      },
    },
  },
  light: {
    brand: {
      staticWhite: '#fff',
      primaryBlue: '#29A3CC',
      boxWhite: ' rgba(255, 255, 255, 0.3)',
      offWhite: '#eee7e7',
      lightBrown:'#6a2e44',
      purple: {
        100: '#675FCC',
        500: '#AD13CC',
      },
      pink: '#D97C78',
      gray: {
        0: '#FFF', // main section
        50: '#FAFAFA',
        100: '#EFEFEF', // darkmode text
        200: '#CECDD5', // lightmode border
        300: '#B5B4BC', // darkmode item name
        400: '#77758A', // icon
        500: '#65646E', // lightmode item name
        600: '#393844', // darkmode border
        700: '#29292C', // lightmode text
        800: '#232323', // darkmode bg
        900: '#212027',
      },
      staticGray: {
        100: '#F4F4F6', // lightmode bg
        500: '#29272F', // menu bg
        900: '#0D0D0D', // darkmode bg
      },
    },
  },
};
