import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';

const variantGradientPrimary: SystemStyleInterpolation = ({ colorScheme }) => {
  const bgGradientColor =
    colorScheme === 'brand'
      ? 'linear(to-r, brand.primaryBlue, brand.purple.500)'
      : `linear(to-r, ${colorScheme}.100, ${colorScheme}.500)`;
  const textColor = colorScheme === 'brand' ? 'brand.staticWhite' : undefined;

  const disabledStyle = {
    background: 'brand.gray.300',
    border: 'none',
    cursor: 'not-allowed',
    '&:hover': {
      background: 'brand.gray.300',
      color: 'brand.staticWhite',
    },
  };

  return {
    color: textColor,
    bgGradient: bgGradientColor,
    w: '100%',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 1,
    borderRadius: 8,
    _hover: {
      opacity: 0.8,
    },
    _disabled: {
      ...disabledStyle,
      _hover: disabledStyle,
    },
  };
};

const variantPrimary: SystemStyleInterpolation = ({ colorScheme, colorMode }) => {
  const baseColor = colorScheme === 'brand' ? 'brand.primaryBlue' : `${colorScheme}.500`;
  const textColor =
    colorScheme === 'brand'
      ? colorMode === 'light'
        ? 'brand.gray.0'
        : 'brand.gray.700'
      : undefined;

  const disabledStyle = {
    transition: 'none',
    backgroundColor: 'brand.gray.300',
    border: 'none',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: 'brand.gray.300',
      color: 'brand.gray.0',
    },
  };

  return {
    color: textColor,
    bg: baseColor,
    borderRadius: 32,
    borderWidth: 1,
    borderColor: baseColor,
    transition: 'all .3s ease-in-out',
    _hover: {
      opacity: 0.8,
    },
    _disabled: {
      ...disabledStyle,
      _hover: disabledStyle,
    },
  };
};

export const buttonConfig: ComponentSingleStyleConfig = {
  defaultProps: {
    colorScheme: 'brand',
    variant: 'primary',
  },
  baseStyle: {
    fontWeight: 'semibold',
  },
  variants: {
    gradientPrimary: variantGradientPrimary,
    primary: variantPrimary,
  },
};
