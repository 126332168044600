import { ChakraProvider } from '@chakra-ui/react';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { ReactElement, ReactNode } from 'react';
import { SWRConfig } from 'swr';
import { WagmiConfig } from 'wagmi';

import { swrConfigValue } from '@/config/swr';
import defaultTheme from '@/config/theme';
import { DynamicThemeProvider } from '@/config/theme/DynamicThemeProvider';
import { wagmiConfig } from '@/config/wagmi';

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SWRConfig value={swrConfigValue}>
      <ChakraProvider theme={defaultTheme}>
        <DynamicThemeProvider>
          <WagmiConfig config={wagmiConfig}>{getLayout(<Component {...pageProps} />)}</WagmiConfig>
        </DynamicThemeProvider>
      </ChakraProvider>
    </SWRConfig>
  );
};

export default appWithTranslation(App);
