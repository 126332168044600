import { IconProps } from '@chakra-ui/react';
import { EthereumIcon } from '@/components/Icons/chains';

interface Network {
  name: string;
  alias: string;
  symbol: string;
  chainId: number;
  rpcUrl: string;
  scanUrl: string;
  icon: (props: IconProps) => JSX.Element;
}

const productionNetwork: Network = {
  name: 'Ethereum Mainnet',
  alias: 'ethereum',
  symbol: 'ETH',
  chainId: 1,
  rpcUrl: 'https://rpc.flashbots.net',
  scanUrl: 'https://etherscan.io/',
  icon: EthereumIcon,
};

const developmentNetwork: Network = {
  name: 'Ethereum Sepolia Testnet',
  alias: 'ethereum-sepolia',
  symbol: 'ETH',
  chainId: 11155111,
  rpcUrl:
    'https://maximum-ultra-waterfall.ethereum-sepolia.quiknode.pro/ca84c63ac852f9c3d2f3a0b3f5a02b7245b525dc/',
  scanUrl: 'https://sepolia.etherscan.io/',
  icon: EthereumIcon,
};

const isUseMainnet = JSON.parse(process.env.NEXT_PUBLIC_USE_MAINNET.toLowerCase());
const isPremain = JSON.parse(process.env.NEXT_PUBLIC_IS_PREMAIN.toLowerCase());

const network = isUseMainnet ? productionNetwork : developmentNetwork;

const mainnetRpcUrl = 'https://rpc.flashbots.net'; // Eth Mainnet

export { type Network };
export { network, mainnetRpcUrl, isUseMainnet, isPremain };
