// this code is generated automatically, don't modify manually;
import { Icon, IconProps } from '@chakra-ui/react';
export const EthereumIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" width={38} height={39} viewBox="0 0 38 39" {...props}>
    <defs>
      <clipPath id="ethereum_svg__a">
        <path d="M0 0h8.742v19.67H0Z" data-name="\u30D1\u30B9 1812" />
      </clipPath>
      <clipPath id="ethereum_svg__b">
        <path d="M0 0h8.742v19.67H0Z" data-name="\u30D1\u30B9 1814" />
      </clipPath>
      <clipPath id="ethereum_svg__c">
        <path d="M0 0h8.742v12.316H0Z" data-name="\u30D1\u30B9 1816" />
      </clipPath>
      <clipPath id="ethereum_svg__d">
        <path d="M0 0h8.746v12.316H0Z" data-name="\u30D1\u30B9 1818" />
      </clipPath>
    </defs>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 67229">
      <path
        fill="#dedede"
        d="M10 0h18a10 10 0 0 1 10 10v19a10 10 0 0 1-10 10H10A10 10 0 0 1 0 29V10A10 10 0 0 1 10 0Z"
        data-name="\u30D1\u30B9 1822"
      />
      <g data-name="\u30B0\u30EB\u30FC\u30D7 4951" opacity={0.45}>
        <g
          clipPath="url(#ethereum_svg__a)"
          data-name="\u30B0\u30EB\u30FC\u30D7 4950"
          transform="translate(10 5)"
        >
          <path fill="#010101" d="m0 14.505 8.742 5.165V0Z" data-name="\u30D1\u30B9 1811" />
        </g>
      </g>
      <g data-name="\u30B0\u30EB\u30FC\u30D7 4953" opacity={0.8}>
        <g
          clipPath="url(#ethereum_svg__b)"
          data-name="\u30B0\u30EB\u30FC\u30D7 4952"
          transform="translate(18.742 5)"
        >
          <path fill="#010101" d="M0 0v19.67l8.739-5.165Z" data-name="\u30D1\u30B9 1813" />
        </g>
      </g>
      <g data-name="\u30B0\u30EB\u30FC\u30D7 4955" opacity={0.45}>
        <g
          clipPath="url(#ethereum_svg__c)"
          data-name="\u30B0\u30EB\u30FC\u30D7 4954"
          transform="translate(10 21.162)"
        >
          <path fill="#010101" d="m0 0 8.742 12.316V5.165Z" data-name="\u30D1\u30B9 1815" />
        </g>
      </g>
      <g data-name="\u30B0\u30EB\u30FC\u30D7 4957" opacity={0.8}>
        <g
          clipPath="url(#ethereum_svg__d)"
          data-name="\u30B0\u30EB\u30FC\u30D7 4956"
          transform="translate(18.742 21.162)"
        >
          <path fill="#010101" d="M0 5.165v7.151L8.746 0Z" data-name="\u30D1\u30B9 1817" />
        </g>
      </g>
    </g>
  </Icon>
);
