import { mainnet, sepolia } from '@wagmi/core/chains';
import { createConfig, configureChains, Chain } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
import { isPremain, isUseMainnet, mainnetRpcUrl } from '@/constants/network';

const supportingChain: Chain = isUseMainnet || isPremain ? mainnet : sepolia;

const providers = isUseMainnet
  ? [
      jsonRpcProvider({
        rpc: () => {
          return { http: mainnetRpcUrl };
        },
      }),
      publicProvider(),
    ]
  : [
      // デフォルトのRPCだとエラーが出るので、sepoliaのsecond RPCを使う
      jsonRpcProvider({
        rpc: () => {
          return { http: 'https://rpc2.sepolia.org' };
        },
      }),
      publicProvider(),
    ];

const { publicClient, webSocketPublicClient, chains } = configureChains(
  [supportingChain],
  providers
);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '1500',
          },
        },
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Slash Vision Portal',
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});
