import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const axiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/`,
});

axiosInstance.interceptors.request.use((req) => {
  if (req.params) {
    req.params = decamelizeKeys(req.params);
  }

  if (req.data) {
    req.data = decamelizeKeys(req.data);
  }

  return req;
});

axiosInstance.interceptors.response.use((res) => {
  res.data = camelizeKeys(res.data);
  return res;
});

export default axiosInstance;
export const getFetcher = <T = AxiosRequestConfig['params'], R = AxiosResponse['data']>(
  url: string,
  params?: T
): Promise<R> => axiosInstance.get(url, { params }).then((res) => res.data);
